package com.stevdza_san.platiparking.styles

import com.stevdza_san.platiparking.models.Theme
import com.varabyte.kobweb.compose.css.Transition
import com.varabyte.kobweb.compose.css.filter
import com.varabyte.kobweb.compose.css.functions.grayscale
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.selectors.hover
import com.varabyte.kobweb.silk.style.selectors.selection
import org.jetbrains.compose.web.css.deg
import org.jetbrains.compose.web.css.ms
import org.jetbrains.compose.web.css.percent

val LogoStyle = CssStyle {
    base {
        Modifier
            .transform { rotate(0.deg) }
            .transition(
                Transition.of(
                    property = "transform",
                    duration = 200.ms
                )
            )
    }
    hover {
        Modifier.transform { rotate((-10).deg) }
    }
}

val NavigationItemStyle = CssStyle {
    base {
        Modifier
            .color(Theme.TextLowVisibility.rgb)
            .transition(
                Transition.of(
                    property = "color",
                    duration = 200.ms
                ),
                Transition.of(
                    property = "opacity",
                    duration = 200.ms
                )
            )
    }
    hover {
        Modifier
            .opacity(value = 1)
            .color(Theme.Primary.rgb)
    }
    selection {
        Modifier
            .backgroundColor(Theme.Primary.rgb)
            .color(Theme.Background.rgb)
    }
}

val NavigationMenuItemStyle = CssStyle {
    base {
        Modifier
            .color(Theme.Text.rgb)
            .transition(
                Transition.of(
                    property = "color",
                    duration = 200.ms
                ),
                Transition.of(
                    property = "opacity",
                    duration = 200.ms
                )
            )
    }
    hover {
        Modifier.color(Theme.Primary.rgb)
    }
}

val StoreImageStyle = CssStyle {
    base {
        Modifier
            .transform { scale(sx = 1, sy = 1) }
            .transition(
                Transition.of(
                    property = "transform",
                    duration = 200.ms
                )
            )
    }
    hover {
        Modifier.transform { scale(sx = 1.1, sy = 1.1) }
    }
    selection {
        Modifier
            .backgroundColor(Theme.Primary.rgb)
            .color(Theme.Background.rgb)
    }
}

val AppPreviewStyle = CssStyle {
    base {
        Modifier
            .transform {
                rotate(a = 0.deg)
                scale(sx = 1, sy = 1)
            }
            .styleModifier {
                filter(grayscale(100.percent))
            }
            .transition(
                Transition.of(property = "filter", duration = 200.ms),
                Transition.of(property = "transform", duration = 200.ms)
            )
    }
    hover {
        Modifier
            .transform {
                rotate(a = 10.deg)
                scale(sx = 1.1, sy = 1.1)
            }
            .styleModifier {
            filter(grayscale(0.percent))
        }
    }
}