package com.stevdza_san.platiparking.section.last

import androidx.compose.runtime.Composable
import com.stevdza_san.platiparking.components.DownloadButton
import com.stevdza_san.platiparking.components.Footer
import com.stevdza_san.platiparking.models.Theme
import com.stevdza_san.platiparking.styles.TextSelectionStyle
import com.stevdza_san.platiparking.util.Constants.SF_PRO_MEDIUM
import com.stevdza_san.platiparking.util.LocalizationManager
import com.varabyte.kobweb.compose.css.BoxShadow
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.layout.Surface
import com.varabyte.kobweb.silk.style.toModifier
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
fun LastSection() {
    Column(
        modifier = Modifier.fillMaxWidth(),
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Surface(modifier = Modifier.height(250.px)) { }
        P(
            attrs = TextSelectionStyle.toModifier()
                .fillMaxWidth()
                .margin(topBottom = 0.px)
                .color(Theme.Text.rgb)
                .fontFamily(SF_PRO_MEDIUM)
                .fontSize(3.cssRem)
                .textAlign(TextAlign.Center)
                .toAttrs()
        ) {
            Text(value = LocalizationManager.getString(key = "section_last_title"))
        }
        Surface(modifier = Modifier.height(25.px)) { }
        DownloadButton()
        Surface(modifier = Modifier.height(400.px)) { }
        Box(
            modifier = Modifier.fillMaxWidth(),
            contentAlignment = Alignment.Center
        ) {
            Box(
                Modifier
                    .fillMaxWidth()
                    .opacity(10.percent)
                    .boxShadow(
                        BoxShadow.of(
                            offsetY = (-25).px,
                            blurRadius = 200.px,
                            spreadRadius = 50.px,
                            color = Theme.Purple.rgb
                        )
                    )
            ) {}
            Footer()
        }
    }
}