package com.stevdza_san.platiparking.components

import androidx.compose.runtime.*
import com.stevdza_san.platiparking.models.Language
import com.stevdza_san.platiparking.models.Section
import com.stevdza_san.platiparking.models.Theme
import com.stevdza_san.platiparking.styles.NavigationMenuItemStyle
import com.stevdza_san.platiparking.util.Constants.SF_PRO_LIGHT
import com.stevdza_san.platiparking.util.LocalizationManager
import com.stevdza_san.platiparking.util.Res
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.ScrollBehavior
import com.varabyte.kobweb.compose.css.TextDecorationLine
import com.varabyte.kobweb.compose.css.Transition
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.*
import com.varabyte.kobweb.compose.ui.graphics.Color.Companion.argb
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.navigation.OpenLinkStrategy
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.layout.Surface
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toModifier
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
fun OverflowMenu(
    opened: Boolean,
    breakpoint: Breakpoint,
    largeBreakpoint: Boolean,
    language: Language,
    onLanguageChange: (Language) -> Unit,
    onMenuClosed: () -> Unit
) {
    val scope = rememberCoroutineScope()
    var translateX by remember { mutableStateOf((-100).percent) }
    var opacity by remember { mutableStateOf(0.percent) }

    LaunchedEffect(opened) {
        if (opened) {
            translateX = 0.percent
            opacity = 100.percent
        } else {
            translateX = (-100).percent
            opacity = 0.percent
        }
    }

    LaunchedEffect(breakpoint) {
        if (largeBreakpoint) {
            scope.launch {
                translateX = (-100).percent
                opacity = 0.percent
                delay(300)
                onMenuClosed()
            }
        }
    }

    Column(
        modifier = Modifier
            .fillMaxWidth()
            .height(100.vh)
            .position(Position.Fixed)
            .zIndex(value = 2)
            .opacity(opacity)
            .backgroundColor(argb(a = 0.5f, r = 0.0f, g = 0.0f, b = 0.0f))
            .transition(
                Transition.of(
                    property = "opacity", duration = 300.ms
                )
            )
            .onClick { onMenuClosed() }
            .thenIf(
                condition = !opened,
                other = Modifier.styleModifier {
                    property("pointer-events", "none")
                }
            ),
    ) {
        Column(
            modifier = Modifier
                .fillMaxHeight()
                .padding(all = 25.px)
                .width(if (breakpoint >= Breakpoint.MD) 40.percent else 70.percent)
                .scrollBehavior(ScrollBehavior.Smooth)
                .backgroundColor(Theme.Background.rgb)
                .translateX(tx = translateX)
                .transition(
                    Transition.of(
                        property = "translate", duration = 300.ms
                    )
                ),
            horizontalAlignment = Alignment.Start,
            verticalArrangement = Arrangement.SpaceBetween
        ) {
            Column {
                Surface(modifier = Modifier.height(20.px)) {}
                Image(
                    modifier = Modifier.size(40.px),
                    src = Res.LOGO,
                    alt = "Logo Image"
                )
                Surface(modifier = Modifier.height(60.px)) {}
                P(
                    attrs = Modifier
                        .fillMaxWidth()
                        .opacity(0.5f)
                        .margin(topBottom = 0.px)
                        .fontFamily(SF_PRO_LIGHT)
                        .fontSize(1.1.cssRem)
                        .color(Theme.Text.rgb)
                        .toAttrs()
                ) {
                    Text(value = LocalizationManager.getString(key = "about_us"))
                }
                Surface(modifier = Modifier.height(20.px)) {}
                Section.entries.toTypedArray().takeLast(4).forEach { section ->
                    Link(
                        modifier = NavigationMenuItemStyle.toModifier()
                            .fontFamily(SF_PRO_LIGHT)
                            .fontSize(1.5.cssRem)
                            .fontWeight(FontWeight.Normal)
                            .textDecorationLine(TextDecorationLine.None)
                            .onClick {
                                scope.launch {
                                    translateX = (-100).percent
                                    opacity = 0.percent
                                    delay(300)
                                    onMenuClosed()
                                }
                            },
                        path = section.path,
                        text = LocalizationManager.getString(key = section.shortTitle),
                        openInternalLinksStrategy = OpenLinkStrategy.IN_PLACE
                    )
                    Surface(modifier = Modifier.height(30.px)) {}
                }
            }
            LanguageSwitch(
                modifier = Modifier.margin(bottom = 80.px),
                language = language,
                onLanguageChange = onLanguageChange
            )
        }
    }
}