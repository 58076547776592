package com.stevdza_san.platiparking.section.preciznost

import androidx.compose.runtime.Composable
import com.stevdza_san.platiparking.components.SectionView
import com.stevdza_san.platiparking.models.Section
import com.stevdza_san.platiparking.styles.TextSelectionStyle
import com.stevdza_san.platiparking.util.Constants.SECTION_WIDTH
import com.stevdza_san.platiparking.util.LocalizationManager
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.id
import com.varabyte.kobweb.compose.ui.modifiers.maxWidth
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toModifier
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun PreciznostSection(
    breakpoint: Breakpoint
) {
    Column(
        modifier = Modifier
            .id(Section.Preciznost.id)
            .fillMaxWidth()
            .maxWidth(SECTION_WIDTH.px)
            .padding(
                leftRight = if (breakpoint > Breakpoint.LG) 200.px
                else if (breakpoint == Breakpoint.LG) 150.px
                else if (breakpoint > Breakpoint.MD) 80.px
                else if (breakpoint == Breakpoint.MD) 60.px
                else 40.px
            )
            .styleModifier {
                property("pointer-events", "none")
            },
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        SectionView(
            section = Section.Preciznost,
            breakpoint = breakpoint
        ) {
            SimpleGrid(
                modifier = Modifier.fillMaxWidth(),
                numColumns = numColumns(base = 1, xl = 2)
            ) {
                Row {
                    Spacer()
                    Image(
                        modifier = TextSelectionStyle.toModifier()
                            .thenIf(
                                condition = breakpoint >= Breakpoint.LG,
                                other = Modifier.padding(right = 24.px)
                            )
                            .maxWidth(if (breakpoint <= Breakpoint.SM) 100.percent else 500.px),
                        src = LocalizationManager.getString(key = Section.Preciznost.images.first()),
                        alt = "Zone Pricing Preview"
                    )
                }
                Row {
                    Image(
                        modifier = TextSelectionStyle.toModifier()
                            .maxWidth(if (breakpoint <= Breakpoint.SM) 100.percent else 500.px),
                        src = LocalizationManager.getString(key = Section.Preciznost.images[1]),
                        alt = "Zone Limitations Preview"
                    )
                    Spacer()
                }
            }
        }
    }
}