package com.stevdza_san.platiparking.section.utisci

import androidx.compose.runtime.Composable
import com.stevdza_san.platiparking.components.SectionView
import com.stevdza_san.platiparking.models.Section
import com.stevdza_san.platiparking.styles.TextSelectionStyle
import com.stevdza_san.platiparking.util.Constants.SECTION_WIDTH
import com.stevdza_san.platiparking.util.LocalizationManager
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toModifier
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun UtisciSection(
    breakpoint: Breakpoint
) {
    Column(
        modifier = Modifier
            .id(Section.Utisci.id)
            .fillMaxWidth()
            .maxWidth(SECTION_WIDTH.px)
            .padding(
                leftRight = if (breakpoint > Breakpoint.LG) 200.px
                else if (breakpoint == Breakpoint.LG) 150.px
                else if (breakpoint > Breakpoint.MD) 80.px
                else if (breakpoint == Breakpoint.MD) 60.px
                else 40.px
            )
            .styleModifier {
                property("pointer-events", "none")
            },
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        SectionView(
            section = Section.Utisci,
            breakpoint = breakpoint
        ) {
            SimpleGrid(numColumns = numColumns(base = 1, xl = 2)) {
                Image(
                    modifier = TextSelectionStyle.toModifier()
                        .thenIf(
                            condition = breakpoint >= Breakpoint.LG,
                            other = Modifier.margin(right = 24.px)
                        )
                        .padding(bottom = 24.px)
                        .maxWidth(if (breakpoint <= Breakpoint.SM) 100.percent else 400.px),
                    src = LocalizationManager.getString(key = Section.Utisci.images.first()),
                    alt = "Testimonial Image"
                )
                Image(
                    modifier = TextSelectionStyle.toModifier()
                        .thenIf(
                            condition = breakpoint >= Breakpoint.LG,
                            other = Modifier.margin(right = 24.px)
                        )
                        .padding(bottom = 24.px)
                        .maxWidth(if (breakpoint <= Breakpoint.SM) 100.percent else 400.px),
                    src = LocalizationManager.getString(key = Section.Utisci.images[1]),
                    alt = "Testimonial Image"
                )
                Image(
                    modifier = TextSelectionStyle.toModifier()
                        .thenIf(
                            condition = breakpoint >= Breakpoint.LG,
                            other = Modifier.margin(right = 24.px)
                        )
                        .padding(bottom = 24.px)
                        .maxWidth(if (breakpoint <= Breakpoint.SM) 100.percent else 400.px),
                    src = LocalizationManager.getString(key = Section.Utisci.images[2]),
                    alt = "Testimonial Image"
                )
                Image(
                    modifier = TextSelectionStyle.toModifier()
                        .maxWidth(if (breakpoint <= Breakpoint.SM) 100.percent else 400.px),
                    src = LocalizationManager.getString(key = Section.Utisci.images[3]),
                    alt = "Testimonial Image"
                )
            }
        }
    }
}