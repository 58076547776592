package com.stevdza_san.platiparking.components

import androidx.compose.runtime.Composable
import com.stevdza_san.platiparking.models.Section
import com.stevdza_san.platiparking.models.Theme
import com.stevdza_san.platiparking.styles.DownloadButtonStyle
import com.stevdza_san.platiparking.styles.TextSelectionStyle
import com.stevdza_san.platiparking.util.Constants.SF_PRO_MEDIUM
import com.stevdza_san.platiparking.util.Id
import com.stevdza_san.platiparking.util.LocalizationManager
import com.stevdza_san.platiparking.util.Res
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.css.TextDecorationLine
import com.varabyte.kobweb.compose.css.UserSelect
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.navigation.OpenLinkStrategy
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.layout.Surface
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.style.toModifier
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
fun DownloadButton() {
    Link(
        modifier = Modifier
            .textDecorationLine(TextDecorationLine.None),
        path = Section.Main.path,
        openExternalLinksStrategy = OpenLinkStrategy.IN_PLACE
    ) {
        Row (
            modifier = DownloadButtonStyle.toModifier()
                .cursor(Cursor.Pointer)
                .userSelect(UserSelect.None)
                .padding(
                    leftRight = 40.px,
                    topBottom = 12.px
                )
                .background(Theme.LightBackground.rgb)
                .border(
                    width = 1.px,
                    style = LineStyle.Solid,
                    color = Theme.LightBorder.rgb
                )
                .borderRadius(r = 50.px),
            verticalAlignment = Alignment.CenterVertically
        ) {
            Image(
                modifier = Modifier
                    .id(value = Id.ARROW_UP)
                    .size(24.px),
                src = Res.ARROW_UP,
                alt = "Arrow Up Icon"
            )
            Surface(modifier = Modifier.width(12.px)) {  }
            P(
                attrs = TextSelectionStyle.toModifier()
                    .id(value = Id.DOWNLOAD_BTN)
                    .fillMaxWidth()
                    .margin(topBottom = 0.px)
                    .color(Theme.Text.rgb)
                    .fontFamily(SF_PRO_MEDIUM)
                    .fontSize(1.2.cssRem)
                    .lineHeight(value = 1.6)
                    .textAlign(TextAlign.Center)
                    .toAttrs()
            ) {
                Text(
                    value = LocalizationManager.getString(key = "section_last_btn_title")
                )
            }
        }
    }
}