package com.stevdza_san.platiparking.components

import androidx.compose.runtime.Composable
import com.stevdza_san.platiparking.models.Theme
import com.varabyte.kobweb.compose.css.functions.radialGradient
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

@Composable
fun BlurredCircle(
    color: CSSColorValue,
    breakpoint: Breakpoint
) {
    Box(
        modifier = Modifier
            .size(if (breakpoint <= Breakpoint.SM) 80.percent else 700.px)
            .backgroundImage(
                radialGradient(
                    from = color,
                    to = Theme.Background.rgb
                )
            )
            .borderRadius(50.percent)
            .opacity(10.percent)
            .styleModifier {
                property("filter", "blur(400px)")
            }
            .translateY(ty = (-200).px)
            .zIndex(value = 0)
    ) { }
}