package com.stevdza_san.platiparking.models

import com.varabyte.kobweb.compose.css.functions.LinearGradient
import com.varabyte.kobweb.compose.css.functions.linearGradient
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.rgb
import org.jetbrains.compose.web.css.rgba

enum class Theme(
    val rgb: CSSColorValue
) {
    Primary(rgb = rgb(r = 255, g = 221, b = 123)),
    Text(rgb = rgb(r = 255, g = 255, b = 255)),
    TextLowVisibility(rgb = rgba(r = 255, g = 255, b = 255, a = 0.5f)),
    Background(rgb = rgb(r = 17, g = 19, b = 24)),
    LightBorder(rgb = rgba(r = 255, g = 255, b = 255, a = 0.2)),
    LightBackground(rgb = rgba(r = 17, g = 19, b = 24, a = 0.25)),
    Red(rgb = rgb(r = 255, g = 85, b = 85)),
    Green(rgb = rgb(r = 61, g = 220, b = 132)),
    Blue(rgb = rgb(r = 62, g = 185, b = 255)),
    Pink(rgb = rgb(r = 255, g = 85, b = 158)),
    Purple(rgb = rgb(r = 138, g = 66, b = 255)),
}

val MainGradient = linearGradient(
    dir = LinearGradient.Direction.ToRight,
    from = rgb(r = 255, g = 221, b = 123),
    to = rgb(r = 27, g = 255, b = 129)
)

val PokrivenostGradient = linearGradient(
    dir = LinearGradient.Direction.ToRight,
    from = rgb(r = 255, g = 185, b = 102),
    to = rgb(r = 255, g = 85, b = 85)
)

val PreciznostGradient = linearGradient(
    dir = LinearGradient.Direction.ToRight,
    from = rgb(r = 61, g = 220, b = 132),
    to = rgb(r = 168, g = 236, b = 255)
)

val PouzdanostGradient = linearGradient(
    dir = LinearGradient.Direction.ToRight,
    from = rgb(r = 62, g = 185, b = 255),
    to = rgb(r = 197, g = 161, b = 255)
)

val UtisciGradient = linearGradient(
    dir = LinearGradient.Direction.ToRight,
    from = rgb(r = 255, g = 85, b = 158),
    to = rgb(r = 255, g = 161, b = 195)
)