package com.stevdza_san.platiparking.util

import androidx.compose.runtime.MutableState
import androidx.compose.runtime.State
import androidx.compose.runtime.mutableStateOf
import com.stevdza_san.platiparking.models.Language

object LocalizationManager {
    private var _language: MutableState<Language> = mutableStateOf(Language.Serbian)
    val language: State<Language> = _language

    private val strings = mapOf(
        Language.Serbian to mapOf(
            "section_main_shortTitle" to "Plati Parking",
            "section_main_title" to "u par klikova",
            "section_main_subTitle" to "Lociramo parking zone putem GPS lokacije u realnom vremenu. Jedinstvena baza koja sadrži podatke o parking zonama širom Srbije!",
            "section_pokrivenost_shortTitle" to "Pokrivenost",
            "section_pokrivenost_title" to "Upoznaj se sa parking zonama oko sebe",
            "section_pokrivenost_subTitle" to "Kvalitetno izrađene mape parking zona će vam pomoći da se snađete gde god se nalazili!",
            "section_preciznost_shortTitle" to "Preciznost",
            "section_preciznost_title" to "Sve korisne informacije na jednom mestu",
            "section_preciznost_subTitle" to "Odaberite cenu i proverite da li postoje ograničenja pre nego što pošaljete poruku.",
            "section_pouzdanost_shortTitle" to "Pouzdanost",
            "section_pouzdanost_title" to "Budite sigurni u tačnost naših podataka",
            "section_pouzdanost_subTitle" to "Konstantno radimo na unapređenju postojećih i mapiranju novih parking zona.",
            "section_utisci_shortTitle" to "Utisci",
            "section_utisci_title" to "Šta drugi misle o nama",
            "section_utisci_subTitle" to "Korisnici koji su koristili našu aplikaciju imaju nešto da vam kažu.",
            "section_last_title" to "Šta čekaš?",
            "section_last_btn_title" to "Preuzmi aplikaciju",
            "about_us" to "O nama",
            "pricing" to "pricing.svg",
            "limitations" to "limitations.svg",
            "availability" to "availability.svg",
            "testimonial1" to "testimonial1.svg",
            "testimonial2" to "testimonial2.svg",
            "testimonial3" to "testimonial3.svg",
            "testimonial4" to "testimonial4.svg",
            "coming_soon" to "U izradi"
        ),
        Language.English to mapOf(
            "section_main_shortTitle" to "Pay Parking",
            "section_main_title" to "in few clicks",
            "section_main_subTitle" to "We locate parking zones through GPS location in real time. A unique database containing data on parking zones throughout Serbia!",
            "section_pokrivenost_shortTitle" to "Coverage",
            "section_pokrivenost_title" to "Get to know the parking zones around you",
            "section_pokrivenost_subTitle" to "Quality maps of parking zones will help you find your way wherever you are!",
            "section_preciznost_shortTitle" to "Precision",
            "section_preciznost_title" to "All useful information in one place",
            "section_preciznost_subTitle" to "Choose a price and check for restrictions before sending a message.",
            "section_pouzdanost_shortTitle" to "Reliability",
            "section_pouzdanost_title" to "Be sure of the accuracy of our data",
            "section_pouzdanost_subTitle" to "We are constantly working on improving existing and mapping new parking zones.",
            "section_utisci_shortTitle" to "Impressions",
            "section_utisci_title" to "What others think about us",
            "section_utisci_subTitle" to "Others who have used our app have something to tell you.",
            "section_last_title" to "Why wait?",
            "section_last_btn_title" to "Download the App",
            "about_us" to "About us",
            "pricing" to "pricing_eng.svg",
            "limitations" to "limitations_eng.svg",
            "availability" to "availability_eng.svg",
            "testimonial1" to "testimonial1_eng.svg",
            "testimonial2" to "testimonial2_eng.svg",
            "testimonial3" to "testimonial3_eng.svg",
            "testimonial4" to "testimonial4_eng.svg",
            "coming_soon" to "In progress"
        )
    )

    fun setLanguage(lang: Language) {
        _language.value = lang
    }

    fun getString(key: String): String {
        return strings[_language.value]?.get(key) ?: "Not found."
    }
}
