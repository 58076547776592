package com.stevdza_san.platiparking.components

import androidx.compose.runtime.Composable
import com.stevdza_san.platiparking.models.Language
import com.stevdza_san.platiparking.models.Section
import com.stevdza_san.platiparking.styles.LogoStyle
import com.stevdza_san.platiparking.styles.NavigationItemStyle
import com.stevdza_san.platiparking.util.Constants.SF_PRO_REGULAR
import com.stevdza_san.platiparking.util.LocalizationManager
import com.stevdza_san.platiparking.util.Res
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.TextDecorationLine
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.layout.Surface
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.style.toModifier
import org.jetbrains.compose.web.css.px

@Composable
fun Header(
    language: Language,
    onLanguageChange: (Language) -> Unit,
    largeBreakpoint: Boolean,
    onMenuClick: () -> Unit
) {
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .padding(topBottom = 50.px),
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.SpaceBetween
    ) {
        if (largeBreakpoint) {
            Row(verticalAlignment = Alignment.CenterVertically) {
                Image(
                    modifier = LogoStyle.toModifier()
                        .size(size = 50.px)
                        .cursor(Cursor.Pointer),
                    src = Res.LOGO,
                    alt = "Logo Image"
                )
                Surface(modifier = Modifier.width(50.px)) {}
                Section.entries.toTypedArray().takeLast(4).forEach { section ->
                    Link(
                        modifier = NavigationItemStyle.toModifier()
                            .padding(right = 34.px)
                            .fontFamily(SF_PRO_REGULAR)
                            .fontSize(16.px)
                            .textDecorationLine(TextDecorationLine.None),
                        path = section.path,
                        text = LocalizationManager.getString(section.shortTitle)
                    )
                }
            }

            LanguageSwitch(
                language = language,
                onLanguageChange = onLanguageChange
            )
        } else {
            Image(
                modifier = Modifier
                    .size(size = 40.px)
                    .cursor(Cursor.Pointer)
                    .onClick { onMenuClick() },
                src = Res.MENU,
                alt = "Hamburger Menu Icon"
            )
        }
    }
}