package com.stevdza_san.platiparking.util

import com.stevdza_san.platiparking.models.Language
import kotlinx.browser.window
import org.w3c.dom.get
import org.w3c.dom.set

fun saveLanguage(language: Language) {
    window.localStorage.set(
        key = "language",
        value = language.name
    )
    LocalizationManager.setLanguage(language)
}

fun readLanguage(): Language {
    return Language.valueOf(
        value = window.localStorage["language"]
            ?: Language.Serbian.name
    )
}