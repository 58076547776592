package com.stevdza_san.platiparking.styles

import com.varabyte.kobweb.compose.css.Transition
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.opacity
import com.varabyte.kobweb.compose.ui.modifiers.transform
import com.varabyte.kobweb.compose.ui.modifiers.transition
import com.varabyte.kobweb.silk.style.CssStyle
import org.jetbrains.compose.web.css.deg
import org.jetbrains.compose.web.css.ms

val DownloadButtonStyle = CssStyle {
    cssRule(" > #arrow_up") {
        Modifier
            .transform { rotate(a = (-90).deg) }
            .opacity(value = 0.38f)
            .transition(
                Transition.of(property = "opacity", duration = 200.ms),
                Transition.of(property = "transform", duration = 200.ms)
            )
    }

    cssRule(":hover > #arrow_up") {
        Modifier
            .transform { rotate(a = 0.deg) }
            .opacity(value = 1f)
    }

    cssRule(" > #download_btn") {
        Modifier
            .opacity(value = 0.38f)
            .transition(Transition.of(property = "opacity", duration = 200.ms),)
    }

    cssRule(":hover > #download_btn") {
        Modifier.opacity(value = 1f)
    }
}