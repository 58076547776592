package com.stevdza_san.platiparking.components

import androidx.compose.runtime.*
import com.stevdza_san.platiparking.models.Theme
import com.varabyte.kobweb.compose.css.AnimationIterationCount
import com.varabyte.kobweb.compose.css.Transition
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.style.animation.Keyframes
import com.varabyte.kobweb.silk.style.animation.toAnimation
import org.jetbrains.compose.web.css.*

@Composable
fun LoadingView(isLoading: Boolean) {
    var opacity by remember { mutableStateOf(100.percent) }

    LaunchedEffect(isLoading) {
        opacity = if (isLoading) 100.percent
        else 0.percent
    }

    Box(
        modifier = Modifier
            .fillMaxSize()
            .background(Theme.Background.rgb)
            .opacity(opacity)
            .transition(
                Transition.of(
                    property = "opacity", duration = 200.ms
                )
            )
            .styleModifier {
                property("pointer-events", "none")
            }
            .zIndex(value = 4),
        contentAlignment = Alignment.Center
    ) {
        Box (
            modifier = Modifier
                .border(
                    width = 3.px,
                    style = LineStyle.Solid,
                    color = Theme.LightBorder.rgb
                )
                .borderTop(
                    width = 3.px,
                    style = LineStyle.Solid,
                    color = Theme.Primary.rgb
                )
                .borderRadius(r = 50.percent)
                .size(25.px)
                .animation(
                    RotationKeyFrames.toAnimation(
                        duration = 800.ms,
                        iterationCount = AnimationIterationCount.Infinite
                    )
                )
        ) {}
    }
}

val RotationKeyFrames = Keyframes {
    from { Modifier.transform { rotate(0.deg) } }
    to { Modifier.transform { rotate(360.deg) } }
}