package com.stevdza_san.platiparking.styles

import com.stevdza_san.platiparking.models.Theme
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.selectors.selection

val TextSelectionStyle = CssStyle {
    base {
        Modifier
    }
    selection {
        Modifier
            .backgroundColor(Theme.Primary.rgb)
            .color(Theme.Background.rgb)
    }
}