package com.stevdza_san.platiparking.components

import androidx.compose.runtime.Composable
import com.stevdza_san.platiparking.models.Language
import com.stevdza_san.platiparking.models.Theme
import com.stevdza_san.platiparking.styles.TextSelectionStyle
import com.stevdza_san.platiparking.util.Constants.SF_PRO_REGULAR
import com.stevdza_san.platiparking.util.YellowParking
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.forms.Switch
import com.varabyte.kobweb.silk.components.layout.Surface
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.toModifier
import org.jetbrains.compose.web.css.px

@Composable
fun LanguageSwitch(
    modifier: Modifier = Modifier,
    language: Language,
    onLanguageChange: (Language) -> Unit
) {
    Row(
        modifier = modifier,
        verticalAlignment = Alignment.CenterVertically
    ) {
        Switch(
            checked = language == Language.English,
            onCheckedChange = {
                onLanguageChange(if (it) Language.English else Language.Serbian)
            },
            thumbColor = if (language == Language.English) Theme.Background.rgb else Theme.LightBackground.rgb,
            colorScheme = YellowParking
        )
        Surface(modifier = Modifier.width(12.px)) { }
        SpanText(
            modifier = TextSelectionStyle.toModifier()
                .color(Theme.Text.rgb)
                .fontFamily(SF_PRO_REGULAR)
                .fontSize(16.px),
            text = "English"
        )
    }
}