package com.stevdza_san.platiparking.pages

import androidx.compose.runtime.*
import com.stevdza_san.platiparking.components.LoadingView
import com.stevdza_san.platiparking.components.OverflowMenu
import com.stevdza_san.platiparking.models.Theme
import com.stevdza_san.platiparking.section.last.LastSection
import com.stevdza_san.platiparking.section.main.MainSection
import com.stevdza_san.platiparking.section.pokrivenost.PokrivenostSection
import com.stevdza_san.platiparking.section.pouzdanost.PouzdanostSection
import com.stevdza_san.platiparking.section.preciznost.PreciznostSection
import com.stevdza_san.platiparking.section.utisci.UtisciSection
import com.stevdza_san.platiparking.util.LocalizationManager
import com.stevdza_san.platiparking.util.readLanguage
import com.stevdza_san.platiparking.util.saveLanguage
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.background
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.layout.Surface
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.px

@Page
@Composable
fun HomePage() {
    val scope = rememberCoroutineScope()
    val breakpoint = rememberBreakpoint()
    val largeBreakpoint = remember(breakpoint) {
        breakpoint > Breakpoint.LG
    }

    val language = remember { LocalizationManager.language }
    var menuOpened by remember { mutableStateOf(false) }
    var isLoading by remember { mutableStateOf(true) }
    var hideMainContent by remember { mutableStateOf(true) }

    LaunchedEffect(key1 = language) {
        // Read saved language from a local storage.
        LocalizationManager.setLanguage(readLanguage())
        delay(2000)
        hideMainContent = false
        isLoading = false
    }

    Box(
        modifier = Modifier
            .fillMaxSize()
            .background(Theme.Background.rgb)
    ) {
        if (!hideMainContent) {
            Column(
                modifier = Modifier.fillMaxSize(),
                verticalArrangement = Arrangement.Top,
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                MainSection(
                    breakpoint = breakpoint,
                    largeBreakpoint = largeBreakpoint,
                    language = language.value,
                    onLanguageChange = {
                        scope.launch {
                            isLoading = true
                            delay(200)
                            hideMainContent = true
                            delay(1000)
                            saveLanguage(it)
                            hideMainContent = false
                            isLoading = false
                        }
                    },
                    onMenuClick = { menuOpened = true }
                )
                Surface(modifier = Modifier.height(300.px)) { }
                PokrivenostSection(breakpoint = breakpoint)
                Surface(modifier = Modifier.height(300.px)) { }
                PreciznostSection(breakpoint = breakpoint)
                Surface(modifier = Modifier.height(300.px)) { }
                PouzdanostSection(breakpoint = breakpoint)
                Surface(modifier = Modifier.height(300.px)) { }
                UtisciSection(breakpoint = breakpoint)
                Surface(modifier = Modifier.height(300.px)) { }
                LastSection()
            }
        }

        OverflowMenu(
            opened = menuOpened,
            breakpoint = breakpoint,
            largeBreakpoint = largeBreakpoint,
            language = language.value,
            onLanguageChange = {
                scope.launch {
                    isLoading = true
                    delay(200)
                    hideMainContent = true
                    delay(1000)
                    saveLanguage(it)
                    hideMainContent = false
                    isLoading = false
                }
            },
            onMenuClosed = { menuOpened = false }
        )
        LoadingView(isLoading = isLoading)
    }
}
