package com.stevdza_san.platiparking.models

import com.varabyte.kobweb.compose.css.functions.Gradient
import com.varabyte.kobweb.compose.css.functions.LinearGradient
import com.varabyte.kobweb.compose.css.functions.linearGradient
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.Color

enum class Section(
    val id: String,
    val images: List<String>,
    val shortTitle: String,
    val title: String,
    val subtitle: String,
    val color: CSSColorValue,
    val gradient: Gradient,
    val path: String,
) {
    Main(
        id = "main",
        images = listOf(),
        shortTitle = "section_main_shortTitle",
        title = "section_main_title",
        subtitle = "section_main_subTitle",
        color = Color.transparent,
        gradient = linearGradient(
            dir = LinearGradient.Direction.ToRight,
            from = Color.transparent,
            to = Color.transparent
        ),
        path = "#main"
    ),
    Pokrivenost(
        id = "pokrivenost",
        images = listOf(),
        shortTitle = "section_pokrivenost_shortTitle",
        title = "section_pokrivenost_title",
        subtitle = "section_pokrivenost_subTitle",
        color = Theme.Red.rgb,
        gradient = PokrivenostGradient,
        path = "#pokrivenost"
    ),
    Preciznost(
        id = "preciznost",
        images = listOf(
            "pricing",
            "limitations",
        ),
        shortTitle = "section_preciznost_shortTitle",
        title = "section_preciznost_title",
        subtitle = "section_preciznost_subTitle",
        color = Theme.Green.rgb,
        gradient = PreciznostGradient,
        path = "#preciznost"
    ),
    Pouzdanost(
        id = "pouzdanost",
        images = listOf("availability"),
        shortTitle = "section_pouzdanost_shortTitle",
        title = "section_pouzdanost_title",
        subtitle = "section_pouzdanost_subTitle",
        color = Theme.Blue.rgb,
        gradient = PouzdanostGradient,
        path = "#pouzdanost"
    ),
    Utisci(
        id = "utisci",
        images = listOf("testimonial1", "testimonial2", "testimonial3", "testimonial4"),
        shortTitle = "section_utisci_shortTitle",
        title = "section_utisci_title",
        subtitle = "section_utisci_subTitle",
        color = Theme.Pink.rgb,
        gradient = UtisciGradient,
        path = "#utisci"
    )
}