package com.stevdza_san.platiparking.components

import androidx.compose.runtime.Composable
import com.stevdza_san.platiparking.models.Theme
import com.stevdza_san.platiparking.styles.NavigationItemStyle
import com.stevdza_san.platiparking.styles.TextSelectionStyle
import com.stevdza_san.platiparking.util.Constants.LINKEDIN
import com.stevdza_san.platiparking.util.Constants.SF_PRO_LIGHT
import com.stevdza_san.platiparking.util.Constants.SF_PRO_MEDIUM
import com.varabyte.kobweb.compose.css.TextDecorationLine
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.navigation.OpenLinkStrategy
import com.varabyte.kobweb.silk.components.layout.Surface
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.toModifier
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.px

@Composable
fun Footer() {
    Row (
        modifier = Modifier
            .fillMaxWidth()
            .background(Theme.Background.rgb)
            .padding(topBottom = 40.px)
            .zIndex(value = 1),
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.Center
    ) {
        SpanText(
            modifier = TextSelectionStyle.toModifier()
                .margin(topBottom = 0.px)
                .color(Theme.TextLowVisibility.rgb)
                .fontFamily(SF_PRO_LIGHT)
                .fontSize(1.cssRem),
            text = "Designed and Developed by"
        )
        Surface(modifier = Modifier.width(6.px)) {  }
        Link(
            modifier = Modifier
                .margin(topBottom = 0.px)
                .color(Theme.Text.rgb)
                .fontFamily(SF_PRO_MEDIUM)
                .fontSize(1.cssRem)
                .textDecorationLine(TextDecorationLine.None),
            path = LINKEDIN,
            openExternalLinksStrategy = OpenLinkStrategy.IN_NEW_TAB
        ) {
            SpanText(
                modifier = NavigationItemStyle.toModifier(),
                text = "Stefan Jovanović"
            )
        }
    }
}