package com.stevdza_san.platiparking.components

import androidx.compose.runtime.Composable
import com.stevdza_san.platiparking.models.Section
import com.stevdza_san.platiparking.models.Theme
import com.stevdza_san.platiparking.styles.TextSelectionStyle
import com.stevdza_san.platiparking.util.Constants.SF_PRO_LIGHT
import com.stevdza_san.platiparking.util.Constants.SF_PRO_MEDIUM
import com.stevdza_san.platiparking.util.LocalizationManager
import com.varabyte.kobweb.compose.css.BackgroundClip
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.layout.Surface
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toModifier
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
fun SectionView(
    section: Section,
    breakpoint: Breakpoint,
    content: @Composable () -> Unit
) {
    Box(
        modifier = Modifier.fillMaxWidth()
            .styleModifier {
                property("pointer-events", "none")
            },
        contentAlignment = Alignment.TopCenter
    ) {
        BlurredCircle(
            color = section.color,
            breakpoint = breakpoint
        )
        Column(
            modifier = Modifier.fillMaxWidth()
                .styleModifier {
                    property("pointer-events", "none")
                }
                .zIndex(value = 1),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            Box(
                modifier = Modifier
                    .padding(
                        leftRight = 60.px,
                        topBottom = 12.px
                    )
                    .background(Theme.LightBackground.rgb)
                    .border(
                        width = 1.px,
                        style = LineStyle.Solid,
                        color = Theme.LightBorder.rgb
                    )
                    .borderRadius(r = 50.px)
                    .styleModifier {
                        property("pointer-events", "none")
                    }
            ) {
                P(
                    attrs = TextSelectionStyle.toModifier()
                        .fillMaxWidth()
                        .margin(topBottom = 0.px)
                        .color(Theme.Text.rgb)
                        .fontFamily(SF_PRO_MEDIUM)
                        .fontSize(1.2.cssRem)
                        .lineHeight(value = 1.6)
                        .textAlign(TextAlign.Center)
                        .toAttrs()
                ) {
                    Text(
                        value = LocalizationManager.getString(section.shortTitle)
                    )
                }
            }

            Surface(modifier = Modifier.height(24.px)) { }

            P(
                attrs = TextSelectionStyle.toModifier()
                    .fillMaxWidth()
                    .maxWidth(if (breakpoint > Breakpoint.LG) 50.percent else 100.percent)
                    .margin(topBottom = 0.px)
                    .backgroundImage(section.gradient)
                    .backgroundClip(BackgroundClip.Text)
                    .color(Color.transparent)
                    .fontFamily(SF_PRO_MEDIUM)
                    .fontSize(4.cssRem)
                    .textAlign(TextAlign.Center)
                    .toAttrs()
            ) {
                Text(value = LocalizationManager.getString(section.title))
            }

            P(
                attrs = TextSelectionStyle.toModifier()
                    .fillMaxWidth()
                    .maxWidth(if (breakpoint > Breakpoint.LG) 50.percent else 100.percent)
                    .margin(top = 20.px)
                    .color(Theme.TextLowVisibility.rgb)
                    .fontFamily(SF_PRO_LIGHT)
                    .fontSize(1.5.cssRem)
                    .lineHeight(value = 1.6)
                    .textAlign(TextAlign.Center)
                    .toAttrs()
            ) {
                Text(value = LocalizationManager.getString(section.subtitle))
            }

            Surface(modifier = Modifier.height(if (breakpoint > Breakpoint.LG) 150.px else 80.px)) { }

            Column(
                modifier = Modifier.fillMaxWidth(),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                content()
            }
        }
    }
}