package com.stevdza_san.platiparking.util

import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.silk.theme.colors.ColorScheme

object YellowParking : ColorScheme {
    override val _50 = Color.rgb(0xFFDD7B)
    override val _100 = Color.rgb(0xFFDD7B)
    override val _200 = Color.rgb(0xFFDD7B)
    override val _300 = Color.rgb(0xFFDD7B)
    override val _400 = Color.rgb(0xFFDD7B)
    override val _500 = Color.rgb(0xFFDD7B)
    override val _600 = Color.rgb(0xFFDD7B)
    override val _700 = Color.rgb(0xFFDD7B)
    override val _800 = Color.rgb(0xFFDD7B)
    override val _900 = Color.rgb(0xFFDD7B)
}